@tailwind base;

@font-face {
  font-family: 'Mona Sans';
  font-weight: 200 900;
  font-display: block;
  font-style: normal;
  font-stretch: 75% 125%;
  src: url('../fonts/Mona-Sans.var.woff2') format('woff2');
}

@layer base {
  :root {


    --white: 0 0% 100%;
    --black: 0 0% 0%;
    --background: 60 9% 98%;
    --background-subtle: 60 9% 94%;
    
    --brand: 262 93% 58%;
    --brand-foreground: 262 93% 90%;
    
    --warn: 46, 97%, 65%;
    --warn-foreground: 46, 97%, 15%;
    
    --alert: 0 100% 65%;
    --alert-foreground: 0 100% 980%;
    
    --primary : 24 10% 10%;
    --primary-foreground: 24 10% 90%;
    
    --secondary :20 6% 94%;
    --secondary-foreground: 0 0% 0%;
    
    --content: 240 10% 3.9%;
    --content-subtle:  240 3.8% 46.1%;
    
    --content-warn: 46, 97%, 40%;
    --content-alert: 0 84.2% 60.2%;


    --border:	24, 6%, 83%;
    --ring:	24, 6%, 83%;


  }
 
  .dark {

    --white: 0 0% 100%;
    --black: 0 0% 0%;
    
    --background: 20 14% 4%;
    --background-subtle: 24 10% 10%;
    

    --brand: 25 95% 53%;
    --brand-foreground: 25 95% 90%;
    
    --warn: 46, 100%, 45%;
    --warn-foreground: 46, 97%, 5%;
    
    --alert: 0 100% 45%;
    --alert-foreground: 0 100% 95%;
    

    --primary : 20 6% 94%;
    --primary-foreground: 20 6% 10%;
  
    --secondary : 24 10% 10%;
    --secondary-foreground: 24 10% 100%;
    
    --content: 240 10% 90%;
    --content-subtle:  240 3.8% 46.1%;
    --content-warn: 46, 100%, 55%;
    --content-alert: 0 84.2% 60.2%;

    --border: 12, 6%, 15%;
    --ring: 12, 6%, 15%;
      
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  html {
    @apply scroll-smooth;
  }
  body {
    @apply bg-background text-content;
  }
}

@-moz-document url-prefix() {
 #root-key-alert-title{
  padding-left: 1.25rem;
 }
}